<template>
  <div class="page">
    <list-layout
      :sort-alias="{
        records_text: 'total_seconds',
      }"
      :filter-form="filterForm"
      :on-fetch="getList"
      :thead="thead"
      @command="onCommand"
      default-sort-key="records_text"
      ref="taskList"
    >
      <template #filter>
        <study-stats-list-filter
          :filterForm="filterForm"
          :jobList="jobList"
          :upload-filter="refreshList"
        ></study-stats-list-filter>
      </template>
      <template #item_user_name="{ row }">
        {{ (row.user ? row.user.nickname : '') | placeholder }}
      </template>
      <template #item_phone="{ row }">
        {{ (row.user ? row.user.phone : '') | placeholder }}
      </template>
      <template #item_user_title="{ row }">
        {{ (row.user ? row.user.title_name : '') | placeholder }}
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from '../../../base/layout/ListLayout'
import {
  exportCollegeCodeIndex,
  exportCollegeCodeRecordIndex,
  getCollegeCodeIndex,
} from '../api/code-manage'
import { getStudyStats } from '../api/college-study-stats'
import StudyStatsListFilter from '../components/StudyStatsListFilter'
import {positionLevel} from '../../organization-admin/api/member/member-list'
export default {
  components: { StudyStatsListFilter, ListLayout },
  data() {
    return {
      filterForm: {
        start_time: -1,
        end_time: -1,
        user_name: '',
        phone: '',
        order_by: 'total_seconds',
        is_desc: 1,
        page: 1,
        page_size: 15,
        positionIds: [],
      },
      thead: [
        {
          label: '排行',
          prop: 'rank',
          minWidth: 100,
          //order_by
          // 必须在
          // total_seconds,
          // total_integrals,
          // total_articles,
          // total_activities,
          // total_courses,
          // total_audios,
          // total_ele_books
          // 范围内
          // sortable: 'custom'
        },
        { label: '名称', prop: 'user_name', type: 'slot', minWidth: 120 },
        { label: '手机号', prop: 'phone', type: 'slot', minWidth: 130 },
        { label: '会内职务', prop: 'user_title', type: 'slot', minWidth: 150 },
        {
          label: '学习时长',
          prop: 'records_text',
          minWidth: 120,
          sortable: 'custom',
        },
        {
          label: '所获积分',
          prop: 'total_integrals',
          minWidth: 120,
          sortable: 'custom',
        },
        {
          label: '签到活动',
          prop: 'total_activities',
          minWidth: 120,
          sortable: 'custom',
        },
        {
          label: '学习课程',
          prop: 'total_courses',
          minWidth: 120,
          sortable: 'custom',
        },
        {
          label: '听书',
          prop: 'total_audios',
          minWidth: 120,
          sortable: 'custom',
        },
        {
          label: '阅读文章',
          prop: 'total_articles',
          minWidth: 120,
          sortable: 'custom',
        },
      ],
      jobList: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    refreshList(q = {}) {
      this.$refs.taskList.getList({
        page: 1,
        ...q,
      })
    },
    init() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 }).then((res) => {
        this.jobList = res.data
      })
    },
    onCommand({ row, command }) {
      switch (command) {
        case 'export':
          this.doExport({
            course_code_id: row.id,
          })
          break
        case 'delete':
        default:
      }
    },
    doExport(extraData) {
      return exportCollegeCodeRecordIndex({
        ...{},
        ...extraData,
      })
    },
    getList(query) {
      return getStudyStats(query)
    },
  },
}
</script>

<style lang="scss" scoped></style>
