var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('list-layout',{ref:"taskList",attrs:{"sort-alias":{
      records_text: 'total_seconds',
    },"filter-form":_vm.filterForm,"on-fetch":_vm.getList,"thead":_vm.thead,"default-sort-key":"records_text"},on:{"command":_vm.onCommand},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('study-stats-list-filter',{attrs:{"filterForm":_vm.filterForm,"jobList":_vm.jobList,"upload-filter":_vm.refreshList}})]},proxy:true},{key:"item_user_name",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")((row.user ? row.user.nickname : '')))+" ")]}},{key:"item_phone",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")((row.user ? row.user.phone : '')))+" ")]}},{key:"item_user_title",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")((row.user ? row.user.title_name : '')))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }